import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

import './plugins/axios'
import './registerServiceWorker'

import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import { VueMaskDirective } from 'v-mask'
import Vue from 'vue'

import aiPlugin from '@/plugins/aiPlugin'

import { name as appName } from '../package.json'
import App from './App.vue'
import filters from './filters'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.use(aiPlugin, { router, appName, instrumentationKey: process.env.VUE_APP_AI_INSTRUMENTATION_KEY })

Vue.directive('mask', VueMaskDirective)

Vue.config.productionTip = false

// setup global eventbus object
Vue.prototype.$eventBus = new Vue()

// automatic global components registration
const requireComponent = require.context(
  './components',
  true,
  /[A-Z]\w+\.(vue|js)$/
)
requireComponent.keys().forEach(fileName => {
  console.info('registering component:', fileName)
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )

  Vue.component(componentName, componentConfig.default || componentConfig)
})

// global filters registration
for (const name in filters) {
  Vue.filter(name, filters[name])
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
