<template>
  <v-col cols="12">
    <span class="subtitle-2">{{ $t('requestForm.accessTags.sectionHeader') }}</span>
    <br />
    <i class="caption">{{ $t('requestForm.accessTags.instruction') }}</i>
    <v-sheet>
      <v-row>
        <v-col md="4" cols="6">
          <v-text-field
            v-model="form.so"
            filled
            :label="$t('requestForm.accessTags.tagSo')"
            :rules="tagIdentifierSoRules"
            required
            @change="update"
          ></v-text-field>
        </v-col>
        <v-col md="4" cols="6">
          <v-text-field
            v-model="form.rc"
            filled
            :label="$t('requestForm.accessTags.tagRc')"
            :rules="tagIdentifierRcRules"
            required
            @change="update"
          ></v-text-field>
        </v-col>
        <v-col md="4" cols="6">
          <v-text-field
            v-model="form.mi"
            filled
            :label="$t('requestForm.accessTags.tagMi')"
            :rules="tagIdentifierMiRules"
            required
            @change="update"
          ></v-text-field>
        </v-col>
        <!-- <v-col md="3" cols="6">
          <v-text-field
            v-model="form.pin"
            filled
            required
            :rules="tagPinRules"
            :label="$t('requestForm.accessTags.pinCode')"
            @change="update"
          ></v-text-field>
        </v-col>-->
      </v-row>
    </v-sheet>
  </v-col>
</template>
<script>
import validators from '../validators'

export default {
  name: 'AccessTags',
  props: {
    value: { type: Object, default: () => {} }
  },
  emits: ['input'],
  data () {
    return {
      ...validators,
      form: { ...this.value }
    }
  },
  methods: {
    update () {
      this.$emit('input', this.form)
    }
  }
}
</script>
